import { handleActions } from 'redux-actions'
import {
  loadShoppingData,
  setShoppingData,
  cleanShoppingData,
  setFreeAlignmentData,
  setAvailabilityData
} from 'actions/shoppingData'
import { QUOTED_ITEMN_AND_SERVICES, FREE_ALIGNMENT_TOOLTIP } from './constants'

// TODO remove mocks from initial state after finish integration.
const initialState = {
  _links: null,
  _state: null,
  totalCount: 0,
  availability: {},
  [QUOTED_ITEMN_AND_SERVICES]: {
    [FREE_ALIGNMENT_TOOLTIP]: {},
    otdPricingToolTipMessage: '',
    installationKits: [],
    orderId: '',
    quoteSummary: {},
    timeSlot: {},
    appliedPromotions: [],
    productPriceChangedQty: 0,
    tppToolTipMessage: '',
    promoCode: [],
    services: [],
    products: []
  }
}

const handleSetFreeAlignmentData = (state, payload) => ({
  ...state,
  [QUOTED_ITEMN_AND_SERVICES]: {
    ...state[QUOTED_ITEMN_AND_SERVICES],
    [FREE_ALIGNMENT_TOOLTIP]: {
      ...state[QUOTED_ITEMN_AND_SERVICES][FREE_ALIGNMENT_TOOLTIP],
      ...payload
    }
  }
})

const handleSetAvailabilityData = (state, payload) => ({
  ...state,
  availability: payload
})

const shoppingData = handleActions({
  [setShoppingData]: (state, { payload }) => ({ ...state, ...payload }),
  [loadShoppingData]: (state, { payload }) => (payload && { ...state, ...payload }) || initialState,
  [cleanShoppingData]: (state, { payload }) => initialState,
  [setFreeAlignmentData]: (state, { payload }) => handleSetFreeAlignmentData(state, payload),
  [setAvailabilityData]: (state, { payload }) => handleSetAvailabilityData(state, payload)
}, initialState)

export default shoppingData
