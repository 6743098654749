import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import Theme from 'components/common/Theme/Theme'
import Link from 'components/common/Link/Link'
import { MenuTitleText } from 'components/Header/HeaderMenuLink/components/SharedStyles'

const MenuItemWrapper = ({
  mobileMainMenuExpanded,
  expanded,
  links,
  name,
  onClick
}) =>
  <MenuItem
    expanded={ expanded }
    hidden={ mobileMainMenuExpanded && !expanded }
    data-at-header-make-appointment-mobile>
    <Title
      menu
      expanded={ expanded }
      data-at-title
      onClick={ onClick }>
      <Icon hidden={ expanded } asset="book-appointment-black" margin="-4px 10px 0 0" width={ 17 } alt="" />
      <RequestAppointmentText narrow>{ name }</RequestAppointmentText>
      <Icon hidden={ expanded } asset="arrow-right" width={ 10 } float="right" margin="0 -20px 0 0" data-at-arrow-right alt="" />
    </Title>
    { expanded && links }
  </MenuItem>

MenuItemWrapper.propTypes = {
  expanded: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.shape()),
  mobileMainMenuExpanded: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func
}


export default MenuItemWrapper


const MenuItem = styled.div`
  position: relative;
  padding: 20px 0 20px 16px;
  text-transform: uppercase;
  ${props => props.expanded && css`
    border-bottom: none;
  `}
  background: ${Theme.colors.chartreuse};
`

const TitleDiv = Link.withComponent('div')
const Title = styled(TitleDiv)`
  transform: none;
  cursor: pointer;
  padding-right: 41px;
  color: ${Theme.colors.white};
  ${props => props.expanded && css`
    padding-bottom: 13px;
    border-bottom: 1px solid ${Theme.colors.light_grey};
    text-align: center;
  `}
  /* stylelint-disable-next-line declaration-colon-newline-after */
  ${props => !props.expanded && css`
    color: ${Theme.colors.white};
  `};

  :hover {
    text-decoration: none;
  }
`

const RequestAppointmentText = styled(MenuTitleText)`
  color: ${Theme.colors.black_new};
`
