import { compose, withHandlers, withProps } from 'recompose'
import { connect } from 'react-redux'
import withType from 'components/common/hoc/withType'
import withRequestApptClick from 'components/common/hoc/withRequestApptClick'
import { FooterMenuLink } from './FooterMenuLink'
import { getCurrentStoreIsE2EAppointment } from 'selectors/getCurrentStore'
import { REQUEST_APPOINTMENT, SCHEDULE_APPOINTMENT } from 'constant'

const mapStateToProps = (state) => ({
  isE2EAppointment: getCurrentStoreIsE2EAppointment(state)
})

const withOpenQuickApptFlow = withHandlers({
  onClick: ({ onClick }) => (event) => {
    event.preventDefault()
    onClick(event)
  }
})


const withQuickAppointmentLinkProps = withProps(({
  isE2EAppointment,
  displayName
}) => ({
  displayName: (displayName === REQUEST_APPOINTMENT && isE2EAppointment) ? SCHEDULE_APPOINTMENT : displayName
}))

export default compose(
  withType({ type: 'BOTAppointmentFooterMenuLink', placeholders: ['links']}),
  connect(mapStateToProps),
  withQuickAppointmentLinkProps,
  withProps(() => ({ navigateToQuickApptPage: true })),
  withRequestApptClick,
  withOpenQuickApptFlow
)(FooterMenuLink)

