import { rest } from 'services'
import { createAction } from 'redux-actions'
import { getIdsNotInStore } from './selectors'
import apiEndPoints from 'services/microServices/endPoints'
import RequestBuilder from 'services/microServices/requestBuilder'

export const addReviews = createAction('BAZAAR_VOICE/ADD_REVIEWS')

export const setRenderBVReviews = createAction('BAZAAR_VOICE/SET_RENDER_BV_REVIEWS')

export const setNestedBVReviews = createAction('BAZAAR_VOICE/SET_NESTED_BV_REVIEWS')

export const setInventoryCount = createAction('BAZAAR_VOICE/SET_INVENTORY_COUNT')

export const clearInventoryCount = createAction('BAZAAR_VOICE/CLEAR_INVENTORY_COUNT')

export const clearNestedBVReviews = createAction('BAZAAR_VOICE/CLEAR_NESTED_BV_REVIEWS')

export const setRatings = ({ bvIds = []}) => (dispatch, getState) => {
  const idsNotInStore = [...new Set(getIdsNotInStore(getState(), { bvIds }))]
  if (idsNotInStore.length) {
    rest
      .api
      .getBazaarVoiceItemReviews(idsNotInStore)
      .then(({
        data: {
          Results = []
        } = {}
      }) => {
        dispatch(addReviews({
          reviews: {
            ...idsNotInStore.reduce((acc, id) => ({ ...acc, [id]: { average: 0, total: 0 }}), {}),
            ...Results.reduce((
              acc, {
                ProductStatistics: {
                  ProductId = '',
                  ReviewStatistics: {
                    AverageOverallRating = 0,
                    TotalReviewCount = 0
                  } = {}
                } = {}
              }) =>
              ({ ...acc, [ProductId]: { average: AverageOverallRating, total: TotalReviewCount }})
            , {})
          }
        }))
      })
  }
}

export const setFullReviews = (bvId) => (dispatch, getState) => {
  rest
    .api
    .getBazaarVoiceItemFullReviews(bvId)
    .then(({
      data: {
        Results = []
      } = {}
    }) => {
      Results && Results.length && dispatch(setNestedBVReviews(Results))
    })
}

const { inventoryCountService: { url, method, basePath }} = apiEndPoints

const getInventoryCountStatus = (payload) =>
  (dispatch) => new Promise((resolve, reject) => {
    new RequestBuilder(url, basePath)
      .withMethod(method)
      .withData(payload)
      .withSuccessHandler((response) => {
        resolve(response)
      })
      .withErrorHandler((error) => {
        reject(error)
      })
      .execute()
  })

export const getInventoryCount = (payload) => async dispatch => {
  await dispatch(getInventoryCountStatus(payload))
    .then(({ data }) => {
      data && data.inventoryCount && dispatch(setInventoryCount(data.inventoryCount))
    })
    .catch(err => console.error(err))
}
