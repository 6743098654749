import React from 'react'
import constant from 'lodash/constant'
import PropTypes from 'prop-types'
import { getOnClickAndOnKeyPressDownEvents } from 'helpers/getOnClickAndOnKeyPressDownEvents'
import styled, { css } from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import Button from 'components/common/Button/Button'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import { DAYS_OF_WEEK } from './constants'
import {
  DefaultWrapper,
  DefaultMonth,
  DefaultWeekDay,
  DefaultCell
} from './components'

const Datepicker = ({
  handleMoveMonthBack,
  handleMoveMonthForward,
  month,
  proceedBack,
  proceedForward,
  weeks,
  isFocusedDate,
  setFocusedRef,
  handleFocusCurrent,
  eventHandlers,
  scrollToFocused,
  components: {
    Wrapper = DefaultWrapper,
    Month = DefaultMonth,
    WeekDay = DefaultWeekDay,
    Cell = DefaultCell
  } = {},
  dataAtAttrs: {
    wrapperAttrs = {},
    cellAttrs = constant({})
  },
  theme = ''
}) =>
  <Wrapper { ...wrapperAttrs } aria-label="Datepicker">
    {
      proceedBack &&
        <MonthButton
          type="button"
          data-cta
          align="left"
          onFocus={ scrollToFocused }
          { ...getOnClickAndOnKeyPressDownEvents(handleMoveMonthBack) }>
          <MonthArrowIcon aria-hidden="true" asset="arrow-datepicker-left" alt="" />
          <Media.ScreenReader>Previous month</Media.ScreenReader>
        </MonthButton>
    }
    {
      proceedForward &&
        <MonthButton
          type="button"
          data-cta
          align="right"
          onFocus={ scrollToFocused }
          { ...getOnClickAndOnKeyPressDownEvents(handleMoveMonthForward) }>
          <MonthArrowIcon aria-hidden="true" asset="arrow-datepicker-right" alt="" />
          <Media.ScreenReader>Next month</Media.ScreenReader>
        </MonthButton>
    }
    <Table>
      <Month headline_inversed block aria-live="polite" role="status">
        <Media.ScreenReader key={ month }>Current month { month }</Media.ScreenReader>
        <Media.NonScreenReader>{ month }</Media.NonScreenReader>
      </Month>
      <thead>
        <tr>
          {
            DAYS_OF_WEEK.map(({ name, title }) =>
              <WeekDay key={ title } title={ title }>
                <Media.NonScreenReader>{ name }</Media.NonScreenReader>
                <Media.ScreenReader>{ title }</Media.ScreenReader>
              </WeekDay>
            )
          }
        </tr>
      </thead>
      <tbody { ...eventHandlers }>
        {
          weeks.map(({ key, days }) =>
            <tr key={ key }>
              {
                days.map(({ displayName, value, date, ...day }) =>
                  <Cell
                    { ...day }
                    { ...cellAttrs(day) }
                    key={ value }
                    ref={ isFocusedDate(date) ? setFocusedRef : null }
                    data-date={ value }
                    tabIndex={ isFocusedDate(date) ? 0 : -1 }
                    aria-label={ `${date.format('dddd MMMM D')}` }
                    theme={ theme }
                    onMouseEnter={ handleFocusCurrent(date) }
                    onFocus={ scrollToFocused }>
                    {!day.empty && <Media.NonScreenReader withoutMouseEvents>{ displayName }</Media.NonScreenReader>}
                  </Cell>
                )
              }
            </tr>
          )
        }
      </tbody>
    </Table>
  </Wrapper>

Datepicker.propTypes = {
  components: PropTypes.shape({
    Wrapper: PropTypes.shape({}),
    Month: PropTypes.shape({}),
    WeekDay: PropTypes.shape({}),
    Cell: PropTypes.shape({})
  }),
  dataAtAttrs: PropTypes.shape({
    wrapperAttrs: PropTypes.shape(),
    cellAttrs: PropTypes.func
  }),
  eventHandlers: PropTypes.shape({
    onClickCapture: PropTypes.func,
    onKeyDownCapture: PropTypes.func
  }),
  handleDateSelect: PropTypes.func,
  handleFocusCurrent: PropTypes.func,
  handleMoveMonthBack: PropTypes.func,
  handleMoveMonthForward: PropTypes.func,
  isFocusedDate: PropTypes.func,
  month: PropTypes.string,
  proceedBack: PropTypes.bool,
  proceedForward: PropTypes.bool,
  scrollToFocused: PropTypes.func,
  setFocusedRef: PropTypes.func,
  theme: PropTypes.string,
  weeks: PropTypes.arrayOf(PropTypes.shape())
}

export default Datepicker

const MonthButton = styled(Button)`
  transform: none;
  position: absolute;
  padding: 0;
  margin-top: 5px;
  background: none;
  border: none;
  ${props => props.align === 'left' ?
    css`
      left: 0;
    ` :
    css`
      right: 0;
    `
}
  ${Mixin.responsive('line-height', '34px', '30px')}

  &:hover {
    background: none;
  }
`

const MonthArrowIcon = styled(Icon)`
  margin: 0 16px;
`

const Table = styled.table`
  width: 100%;
  border-spacing: 2px;

  & *:not(caption) {
    font-weight: 600;
  }
`
