export const getAllReviews = ({ bazaarVoiceReviews: { bazaarVoiceInlineReviews }}) => bazaarVoiceInlineReviews

export const getRenderBVReviews = ({ bazaarVoiceReviews: { renderBVReviews }}) => renderBVReviews

export const getNestedBVReviews = ({ bazaarVoiceReviews: { nestedBVReviews }}) => nestedBVReviews

export const getInventoryCountStateValue = ({ bazaarVoiceReviews: { inventoryCount }}) => inventoryCount

export const getIdsNotInStore = (state, { bvIds = []}) => {
  const currentBVRatings = getAllReviews(state)
  return bvIds.filter(bvId => Boolean(!currentBVRatings[bvId]))
}
