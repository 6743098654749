import { handleActions } from 'redux-actions'
import { setServiceCategory } from './actions'

const initialState = {
  serviceCategory: {}
}

const serviceCategoryDetails = handleActions({
  [setServiceCategory]: (state, { payload }) => ({
    ...state,
    serviceCategory: payload
  })
}, initialState)

export default serviceCategoryDetails
