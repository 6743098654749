import { createGlobalStyle } from 'styled-components'
import Mixin from 'components/common/Mixin'
import Media from 'components/common/Media'
import Theme from 'components/common/Theme/Theme'

import FactoriaUltraTTF from './fonts/factoria_ultra-webfont.ttf'
import FactoriaUltraWOFF from './fonts/factoria_ultra-webfont.woff'
import FactoriaUltraWOFF2 from './fonts/factoria_ultra-webfont.woff2'

import MakoMediumWOFF from './fonts/mako_medium.woff'
import MakoMediumWOFF2 from './fonts/mako_medium.woff2'
import MakoMediumTTF from './fonts/mako_medium.ttf'
import MakoBoldWOFF from './fonts/mako_bold.woff'
import MakoBoldWOFF2 from './fonts/mako_bold.woff2'
import MakoBoldTTF from './fonts/mako_bold.ttf'
import MakoExtraBoldWOFF from './fonts/mako_extra_bold.woff'
import MakoExtraBoldWOFF2 from './fonts/mako_extra_bold.woff2'
import MakoExtraBoldTTF from './fonts/mako_extra_bold.ttf'

import GothamMediumWOFF from './fonts/gotham_medium.woff'
import GothamMediumWOFF2 from './fonts/gotham_medium.woff2'
import GothamMediumTTF from './fonts/gotham_medium.ttf'
import GothamBoldWOFF from './fonts/gotham_bold.woff'
import GothamBoldWOFF2 from './fonts/gotham_bold.woff2'
import GothamBoldTTF from './fonts/gotham_bold.ttf'
import GothamLightWOFF from './fonts/gotham_light.woff'
import GothamLightWOFF2 from './fonts/gotham_light.woff2'
import GothamLightTTF from './fonts/gotham_light.ttf'
import GothamMediumItalicWOFF from './fonts/gotham_medium_italic.woff'
import GothamMediumItalicWOFF2 from './fonts/gotham_medium_italic.woff2'
import GothamMediumItalicTTF from './fonts/gotham_medium_italic.ttf'
import GothamBookItalicWOFF from './fonts/gotham-book_italic.woff'
import GothamBookItalicWOFF2 from './fonts/gotham-book_italic.woff2'
import GothamBookItalicTTF from './fonts/gotham-book_italic.ttf'
import GothamBookWOFF from './fonts/gotham_book.woff'
import GothamBookWOFF2 from './fonts/gotham_book.woff2'
import GothamBookTTF from './fonts/gotham_book.ttf'

import { MAX_WIDTH } from 'constant'
import ArrowIconFullBrightRedRight from 'components/common/Icon/assets/arrow-icon-full-bright-red-right.svg'

import FilledDownArrowChartreuse from 'components/common/Icon/assets/Filled-down-arrow-chartreuse.svg'

import IconInfoBlueRegular from 'components/common/Icon/assets/Icon-info-blue-regular.svg'
import IconInfoBlueSolid from 'components/common/Icon/assets/Icon-info-blue-solid.svg'

const GlobalStyles = createGlobalStyle`
  [hidden] {
    display: none !important;
  }

  [data-non-empty]:empty {
    display: none;
  }

  [data-cta] {
    cursor: pointer;
  }

  [data-sticky] {
    [data-sticky-hide] {
      display: none !important;
    }
  }

  [data-sr-only] {
    ${Mixin.srOnly()}
  }

  [data-desktop-only] {
    ${Mixin.desktopOnly()}
  }

  [data-mobile-only] {
    ${Mixin.mobileOnly()}
  }

  [data-relative] {
    position: relative;
  }

  [data-max-width] {
    max-width: ${MAX_WIDTH}px;
    margin: 0 auto;
  }

  [data-max-width-padding=true] {
    ${Media.desktop`
      padding: 0 50px;
    `}
  }

  * {
    box-sizing: border-box;
    ${Mixin.font.GothamBook(14)}
  }

  html {
    color: ${Theme.colors.black};
    line-height: 1.15;
  }

  html,
  body {
    margin: 0;
  }

  main {
    display: block;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
  }

  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: button;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }

  b,
  strong {
    font-weight: bold;
  }

  li {
    list-style-type: none;
  }

  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    min-width: 0;
  }

  @font-face {
    font-family: 'Factoria';
    font-display: swap;
    src:
      url(${FactoriaUltraTTF}) format('ttf'),
      url(${FactoriaUltraWOFF}) format('woff'),
      url(${FactoriaUltraWOFF2}) format('woff2');
  }

  @font-face {
    font-family: 'MakoMedium';
    font-display: swap;
    font-weight: 500;
    src:
      url(${MakoMediumWOFF}) format('woff'),
      url(${MakoMediumWOFF2}) format('woff2'),
      url(${MakoMediumTTF}) format('ttf');
  }

  @font-face {
    font-family: 'MakoBold';
    font-display: swap;
    font-weight: 700;
    src:
      url(${MakoBoldWOFF}) format('woff'),
      url(${MakoBoldWOFF2}) format('woff2'),
      url(${MakoBoldTTF}) format('ttf');
  }

  @font-face {
    font-family: 'MakoExtraBold';
    font-display: swap;
    font-weight: 800;
    src:
      url(${MakoExtraBoldWOFF}) format('woff'),
      url(${MakoExtraBoldWOFF2}) format('woff2'),
      url(${MakoExtraBoldTTF}) format('ttf');
  }

  @font-face {
    font-family: 'GothamMedium';
    font-display: swap;
    font-weight: 500;
    src:
      url(${GothamMediumWOFF}) format('woff'),
      url(${GothamMediumWOFF2}) format('woff2'),
      url(${GothamMediumTTF}) format('ttf');
  }

  @font-face {
    font-family: 'GothamBold';
    font-display: swap;
    font-weight: 700;
    src:
      url(${GothamBoldWOFF}) format('woff'),
      url(${GothamBoldWOFF2}) format('woff2'),
      url(${GothamBoldTTF}) format('ttf');
  }

  @font-face {
    font-family: 'GothamLight';
    font-display: swap;
    font-weight: 300;
    src:
      url(${GothamLightWOFF}) format('woff'),
      url(${GothamLightWOFF2}) format('woff2'),
      url(${GothamLightTTF}) format('ttf');
  }

  @font-face {
    font-family: 'GothamMediumItalic';
    font-display: swap;
    font-weight: 500;
    src:
      url(${GothamMediumItalicWOFF}) format('woff'),
      url(${GothamMediumItalicWOFF2}) format('woff2'),
      url(${GothamMediumItalicTTF}) format('ttf');
  }

  @font-face {
    font-family: 'GothamBookItalic';
    font-display: swap;
    font-weight: 400;
    src:
      url(${GothamBookItalicWOFF}) format('woff'),
      url(${GothamBookItalicWOFF2}) format('woff2'),
      url(${GothamBookItalicTTF}) format('ttf');
  }

  @font-face {
    font-family: 'GothamBook';
    font-display: swap;
    font-weight: 400;
    src:
      url(${GothamBookWOFF}) format('woff'),
      url(${GothamBookWOFF2}) format('woff2'),
      url(${GothamBookTTF}) format('ttf');
  }

  /* HTML content block */
  .generic-list {
    margin: 0;
    padding: 10px 0;
    list-style: none;
    counter-reset: list;

    &[number] > li::before {
      content: counter(list);
    }

    & > li {
      padding: 3px 3px 3px 15px;
      margin-bottom: 10px;
      line-height: 20px;
      color: ${Theme.colors.black};

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        color: ${Theme.colors.dark_red};
        counter-increment: list;
        content: '•';
        float: left;
        margin-left: -15px;
      }
    }
  }

  .generic-list-title {
    margin-top: 21px;
    color: #1e1e1e;
    text-transform: uppercase;
    ${Mixin.font.GothamMedium(14)}
  }

  .generic-banner-headline {
    ${Mixin.font.GothamBook(20)}
    color: #333;
    text-decoration: none;
    text-transform: uppercase;

    :hover {
      text-decoration: underline;
    }

    ::after {
      content: '';
      background-image: url(${ArrowIconFullBrightRedRight});
      display: inline-block;
      background-repeat: no-repeat;
      background-size: contain;
      height: 15px;
      width: 5px;
      margin-right: -3px;
      position: relative;
      top: 5px;
      left: 6px;
    }
  }

  .generic-button {
    display: inline-block;
    padding: 9px 23px 5px;
    background-color: #ed0d0f;
    border: none;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    ${Mixin.font.GothamBold(12)}
    transform: scale(1, 1.3);

    + .generic-button {
      margin-left: 10px;
    }

    &.button-small {
      padding: 9px 12px 8px;
      font-size: 14px;
      line-height: 14px;
    }

    &:hover {
      background: #96181a;
      color: #fff;
    }
  }

  .tire-details-wrapper {
    margin: 20px 0 10px;
    line-height: 22px;
    max-height: 264px;
  }

  .tire-details-wrapper li {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .tire-details-wrapper p {
    margin: 0;
  }

  .generic-description {
    padding-bottom: 22px;
  }

  .tire-details-wrapper .generic-list {
    margin: 10px;
  }

  .tire-details-wrapper .read-more {
    color: #1262ac;
    font-weight: 600;
  }

  /* Print styles */
  [data-printonly] {
    display: none;
  }

  @media print {
    [data-printless] {
      display: none !important;
    }

    [data-printonly] {
      display: block;
    }

    html > body {
      height: auto !important;
    }
  }

  [data-html-block] {
    h1 {
      display: block;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: ${Theme.colors.black_new};
      ${Mixin.font.MakoExtraBold(24)}
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      ${Mixin.font.MakoBold()}
    }

    .generic-headline {
      margin: 0 0 20px;
      letter-spacing: 0.5px;
      color: #1e1e1e;
      text-transform: uppercase;
      ${Mixin.font.MakoExtraBold(24)}
    }

    @media (max-width: 1023px) {
      .generic-headline {
        margin: 0 0 10px;
        font-size: 30px;
        line-height: 43px;
      }

      .mobile-padding {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    a {
      ${Mixin.font.GothamBook()}
      text-decoration: underline;
      cursor: pointer;
      color: ${Theme.colors.blue};
    }

    a:hover {
      text-decoration: none;
    }

    p {
      margin: 0 0 15px;
      font-size: 14px;
      line-height: 22px;
    }

    .responsive-table {
      width: 100%;
      overflow-x: auto;
    }

    table .align-top {
      vertical-align: top;
    }
  }

  .h1-title-alt {
    ${Mixin.font.GothamBold()}
    line-height: 1.05em;
    transform: scale(1, 1.5);
  }

  .teko-alt {
    ${Mixin.font.GothamBold()}
    transform: scale(1, 1.3);
  }

  .open-sans-alt {
    ${Mixin.font.GothamBold()}
    line-height: 1.3em;
  }

  .cta-alt {
    ${Mixin.font.GothamBold()}
    letter-spacing: 0.05em;
  }

  .link-alt {
    ${Mixin.font.MakoMedium()}
    letter-spacing: -0.01em;
    line-height: 1.3em;
  }

  ${Media.mobile`
    #smg-feedbackbtn {
      visibility: hidden !important;
    }

    #ae_launcher {
      visibility: hidden !important;
    }
  `}

  .ev-tires-tab-label::after {
    content: '';
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background-image: url(${FilledDownArrowChartreuse});
    background-repeat: no-repeat;
    text-align: center;
    position: absolute;
    right: 90px;
    transition: all 350ms;
  }

  ${Media.mobile`
    .ev-tires-tab-label::after {
      right: 20px;
    }
  `}

  .featured-ev-tires__tooltip-icon {
    background-size: 14px 14px;
    background-image: url(${IconInfoBlueRegular});
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .featured-ev-tires__tooltip-input:checked ~ .featured-ev-tires__tooltip-icon {
    background-image: url(${IconInfoBlueSolid});
  }
`

export default GlobalStyles
