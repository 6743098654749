import { handleActions } from 'redux-actions'
import {
  addReviews,
  setRenderBVReviews,
  setNestedBVReviews,
  setInventoryCount,
  clearInventoryCount,
  clearNestedBVReviews
} from './actions'

const initialState = {
  bazaarVoiceInlineReviews: {},
  renderBVReviews: false,
  nestedBVReviews: [],
  inventoryCount: null
}

const bazaarVoiceReviews = handleActions({
  [addReviews]: (state, { payload: { reviews }}) =>
    ({ ...state,
      bazaarVoiceInlineReviews: {
        ...state.bazaarVoiceInlineReviews, ...reviews
      }
    }),
  [setRenderBVReviews]: (state, { payload }) =>
    ({ ...state,
      renderBVReviews: payload
    }),
  [setNestedBVReviews]: (state, { payload }) =>
    ({ ...state,
      nestedBVReviews: payload
    }),
  [setInventoryCount]: (state, { payload }) =>
    ({ ...state,
      inventoryCount: payload
    }),
  [clearInventoryCount]: (state, { payload }) =>
    ({ ...state,
      inventoryCount: null
    }),
  [clearNestedBVReviews]: (state, { payload }) =>
    ({ ...state,
      nestedBVReviews: []
    })
}, initialState)

export default bazaarVoiceReviews
