import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { reducer as form } from 'redux-form'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { reducer as modal } from 'redux-modal'
import app from 'components/Main/reducer'
import browser from 'components/common/Media/reducer'
import ymme from 'components/common/hoc/withYmme/reducer'
import shopByLicencePlate from 'components/VehicleConfigurator/components/withLicencePlate/reducer'
import tireSize from 'components/VehicleConfigurator/components/withTireSize/reducer'
import navigations from 'components/NavigationsTracker/reducer'
import globalSearchModal from 'components/GlobalSearchModal/reducer'
import subscription from 'components/EmailSignUp/components/EmailSignUpForm/reducer'
import showHidePasswordInputs from 'components/common/hoc/withShowHidePassword/reducer'
import selectMyLocation from 'components/common/hoc/withMakeThisMyLocation/reducers'
import personalInformation from 'components/MyAccount/components/PersonalInformationDrawer/reducer'
import brandDetails from 'components/BrandDetailsPage/reducer'
import storeLocator from 'components/StoreLocatorPage/reducers'
import storeLocatorModal from 'components/common/StoreLocatorModal/reducer'
import appointment from 'components/QuickAppointmentPage/reducer'
import communicationPreferences from 'components/MyAccount/components/CommunicationPreferencesDrawer/reducer'
import storeDetailsInformation from 'components/StoreDetailsPage/components/StoreInformation/reducer'
import gBBDetails from 'components/common/hoc/withGBBDetails/reducer'
import relatedArticles from 'components/BOTArticlePage/reducer'
import serviceHistoryPage from 'components/ServiceHistory/components/BOTServiceHistory/reducer'
import thirdPartyData from 'components/common/hoc/with3rdPartyData/reducer'
import updatingBlocks from 'components/common/UpdatingBlock/reducer'
import headerSystemAlert from 'components/Header/HeaderSystemAlert/reducer'
import appointmentCheckout from 'components/AppointmentConfirmationPage/Checkout/components/AppointmentInfoDrawer/reducer'
import sticky from 'components/common/hoc/withSticky/reducer'
import favorites from 'components/MyFavorites/reducer'
import promotionModal from 'components/BOTPromotionBannerAndModal/CustomPromotionModal/reducer'
import availableTires from 'components/common/AvailableTires/reducer'
import promotionExpirationStatus from 'components/ShoppingQuote/BOTProductAndServicesQuoteNew/components/PromotionExpirationModal/reducer.js'
import serviceCategoryDetails from 'components/StoreDetailsPage/components/StoreServices/components/reducer.js'

// Add product to cart button plus modal
import promotionsLightBox from 'components/common/hoc/withPromotionLightBox/reducer'
import addToQuote from 'components/common/hoc/withAddToQuote/reducer'
// confirm tire size
import { confirmTireSizeReducer, confirmTireSizeMiddleware } from 'components/VehicleConfigurator'

// Shopping data
import shoppingData from 'components/common/hoc/withShoppingData/reducer'
import previousOrder from 'components/common/hoc/withPreviousOrder/reducer'
import shoppingDataMiddleware from 'components/common/hoc/withShoppingData/middleware'
// import redirectToCart from 'components/common/hoc/withRedirectToCart/middleware'
import myStore from 'components/Header/MyStore/reducer'
import quoteEmailData from 'components/BOTShareViaEmailForms/reducers'
import myAppointments from 'components/Header/AccountAndSignIn/HeaderAccountMenu/MyAppointmentsMenuLink/reducer'
import { TireDetailsReducer as skuId } from 'components/TireDetailsPage'
import findLowerPrice from 'components/FindLowerPriceModal/components/BOTSkuFindLowerPriceInfo/reducer'
import vehicleEditor from 'src/components/VehicleEditor/reducer'

import searchResultPage from 'components/OneColumnSearchResultPage/reducer'
import tireLinePage from 'components/TireLine/reducer'
import globalSearchPage from 'components/GlobalSearch/reducer'

import bazaarVoiceReviews from 'components/common/hoc/withBazaarVoiceInlineRatings/reducer'
import SSR from 'server/server-rendering/reducer'

import GTMMiddleware from 'services/GTM/middleware'
import FullStoryMiddleware from 'services/ThirdPartyMiddleware/FullStory'
import KenshooMiddleware from 'services/ThirdPartyMiddleware/Kenshoo'
import BingMiddleware from 'services/ThirdPartyMiddleware/Bing'
import FacebookMiddleware from 'services/ThirdPartyMiddleware/Facebook'
import GoogleOptimizeMiddleware from 'services/ThirdPartyMiddleware/GoogleOptimize'
import ABTestingMiddleware from 'services/ThirdPartyMiddleware/ABTesting'

import favoritesMiddleware from 'components/MyFavorites/middleware'
import { loadFavoriteSkus } from 'components/MyFavorites/actions'

const reducer = (history) => combineReducers({
  app,
  form,
  modal,
  ymme,
  shopByLicencePlate,
  tireSize,
  browser,
  navigations,
  subscription,
  globalSearchModal,
  showHidePasswordInputs,
  personalInformation,
  brandDetails,
  router: connectRouter(history),
  selectMyLocation,
  storeLocator,
  storeLocatorModal,
  appointment,
  myAppointments,
  communicationPreferences,
  addToQuote,
  promotionsLightBox,
  shoppingData,
  previousOrder,
  myStore,
  quoteEmailData,
  skuId,
  findLowerPrice,
  vehicleEditor,
  confirmTireSizeData: confirmTireSizeReducer,
  searchResultPage,
  globalSearchPage,
  tireLinePage,
  bazaarVoiceReviews,
  storeDetailsInformation,
  gBBDetails,
  relatedArticles,
  serviceHistoryPage,
  thirdPartyData,
  SSR,
  updatingBlocks,
  headerSystemAlert,
  appointmentCheckout,
  sticky,
  favorites,
  promotionModal,
  availableTires,
  promotionExpirationStatus,
  serviceCategoryDetails
})

export default function configureStore(predefinedState = {}, history) {
  const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) :
      compose

  const middleware = [
    thunk,
    routerMiddleware(history),
    shoppingDataMiddleware,
    confirmTireSizeMiddleware,
    GTMMiddleware,
    FullStoryMiddleware,
    KenshooMiddleware,
    FacebookMiddleware,
    BingMiddleware,
    GoogleOptimizeMiddleware,
    ABTestingMiddleware,
    favoritesMiddleware
  ]

  if (process.env.NODE_ENV !== 'production') {
    middleware.push(reduxImmutableStateInvariant())
  }

  const enhancer = composeEnhancers(
    applyMiddleware(...middleware)
  )

  const store = createStore(
    reducer(history),
    predefinedState,
    enhancer
  )

  store.dispatch(loadFavoriteSkus())

  return store
}
