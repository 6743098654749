import { getFormValues } from 'redux-form'
import dayjs from 'dayjs'
import { getAppointmentConfirmationContactDetails } from '../AppointmentConfirmationPage/Checkout/selectors'
import { getActiveUserVehicleId, populateContactInfoField } from './helpers'
import {
  APPOINTMENT_FORM_NAME,
  VEHICLE_SELECTOR_FIELD_NAME,
  STORE_SELECTOR_FIELD_NAME,
  USER_EMAIL,
  USER_FIRST_NAME,
  USER_LAST_NAME,
  USER_PHONE_NUMBER,
  USER_PLAN_FIELD_NAME,
  USER_PLAN_WAIT,
  USER_PLAN_DROP_OFF, SERVICES_SELECTOR_FIELD_NAME
  , ACTIVE_PROMOTIONS_FIELD_NAME } from 'constant'
import { createSelector } from '../../helpers/reselect'
import { getAppointments } from 'selectors/getAppointments'

export const getValues = getFormValues(APPOINTMENT_FORM_NAME)

export const getUserData = ({
  app: {
    data: {
      userdata: {
        userEmail,
        email,
        userName,
        firstName,
        lastName,
        primaryPhoneNumber,
        phoneNumber,
        isPilotStore
      } = {}
    } = {}
  } = {}
}) => ({
  userEmail,
  email,
  userName,
  firstName,
  lastName,
  primaryPhoneNumber,
  phoneNumber,
  isPilotStore
})

export const getInitialValues = ({
  appointment: {
    vehicles,
    preSelectedServices,
    activePromotions
  } = {},
  myStore,
  ...state
}) => {
  const values = getValues(state)
  const {
    userEmail,
    email,
    userName,
    firstName,
    lastName,
    primaryPhoneNumber,
    phoneNumber
  } = getUserData(state)
  const storedContactDetails = getAppointmentConfirmationContactDetails(state)

  return {
    initialValues: {
      ...values,
      [USER_EMAIL]: (values && values[USER_EMAIL] === '') ? null : populateContactInfoField(values, USER_EMAIL, (userEmail || email), storedContactDetails),
      [USER_FIRST_NAME]: (values && values[USER_FIRST_NAME] === '') ? null : populateContactInfoField(values, USER_FIRST_NAME, (userName || firstName),
        storedContactDetails),
      [USER_LAST_NAME]: (values && values[USER_LAST_NAME] === '') ? null :
        populateContactInfoField(values, USER_LAST_NAME, lastName, storedContactDetails),
      [USER_PHONE_NUMBER]: (values && values[USER_PHONE_NUMBER] === '') ? null : populateContactInfoField(
        values,
        USER_PHONE_NUMBER,
        (primaryPhoneNumber || phoneNumber),
        storedContactDetails
      ),
      [VEHICLE_SELECTOR_FIELD_NAME]: getActiveUserVehicleId(vehicles),
      [STORE_SELECTOR_FIELD_NAME]: myStore ? myStore.storeNumber : undefined,
      [USER_PLAN_FIELD_NAME]: populateContactInfoField(values, USER_PLAN_FIELD_NAME, null, storedContactDetails),
      [SERVICES_SELECTOR_FIELD_NAME]: preSelectedServices || [],
      [ACTIVE_PROMOTIONS_FIELD_NAME]: activePromotions || []
    }
  }
}

export const getInitialValuesObject = ({
  appointment: {
    vehicles,
    preSelectedServices
  } = {},
  myStore
}) => ({
  [VEHICLE_SELECTOR_FIELD_NAME]: getActiveUserVehicleId(vehicles),
  [STORE_SELECTOR_FIELD_NAME]: myStore ? myStore.storeNumber : undefined,
  [USER_PLAN_FIELD_NAME]: USER_PLAN_WAIT,
  [SERVICES_SELECTOR_FIELD_NAME]: preSelectedServices || []
})

export const getDateTimeRequestData = ({ date, time } = {}) => ({
  appointmentDateText: dayjs(date).format('MM/DD/YYYY'),
  appointmentTimeText: time.value,
  userPlan: USER_PLAN_DROP_OFF
})

export const getOrderId = ({
  quotedItemAndServices: {
    orderId
  } = {}
}) => orderId

export const getQuickAppointmentStepper = ({ thirdPartyData }) =>
  thirdPartyData.thirdParty.quickAppointmentStepper

export const getQuickApptInitialized = ({ appointment: { initialized } = {}}) => initialized

export const getPreSelectedServices = createSelector(
  [getAppointments],
  appointment => appointment && appointment.preSelectedServices
)
