import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import Icon from 'components/common/Icon/Icon'
import Theme from 'components/common/Theme/Theme'
import {
  AVAILABLE_BY_DATE,
  AVAILABLE_TODAY,
  AVAILABLE_TOMORROW,
  CONTACT_STORE_TEXT,
  CALL_STORE_TEXT
} from './constants'

dayjs.extend(customParseFormat)

const TireAvailability = React.memo(({
  showContactStoreModal,
  isChangeModal = false,
  availability: {
    availableAsOf,
    availableTomorrow,
    availableToday
  } = {},
  lg = false
}) => {
  let text = ''
  if (availableToday) {
    text = AVAILABLE_TODAY
  } else if (availableTomorrow) {
    text = AVAILABLE_TOMORROW
  } else if (availableAsOf) {
    text = `${AVAILABLE_BY_DATE} ${dayjs(availableAsOf, 'MM-DD-YYYY').format('MM/DD')}`
  } else {
    text = CALL_STORE_TEXT
  }

  const handleEnterKeyPress = e => {
    if (e.key === Theme.keyString.ENTER) {
      showContactStoreModal()
    }
  }

  const renderContactStorText = !availableToday && !availableTomorrow && !isChangeModal && availableAsOf
    ? <ContactStore tabIndex="0" onClick={ showContactStoreModal } onKeyPress={ handleEnterKeyPress }>
      {CONTACT_STORE_TEXT}
    </ContactStore>
    : null

  return (
    <AvailabilityText>
      <>
        <CheckCircleIcon asset="check-circle" alt="Check circle" />
        <TextHolder lg={ lg } spaced={ lg && availableToday } >
          <span className={ text === CALL_STORE_TEXT ? 'ga_app_ call-store-translation' : 'ga_app_' }>{text}</span>
        </TextHolder>
        {renderContactStorText}
      </>
    </AvailabilityText>
  )
})

TireAvailability.propTypes = {
  availability: PropTypes.shape(),
  isChangeModal: PropTypes.bool,
  lg: PropTypes.bool,
  showContactStoreModal: PropTypes.func
}

export default TireAvailability

const AvailabilityText = styled.div`
  color: ${Theme.colors.green};
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const TextHolder = styled.span`
  float: left;
  font-size: ${({ lg }) => lg ? '16px' : '14px'};
  margin-bottom: ${({ spaced }) => spaced ? '5px' : ''};
  font-weight: bold;
  line-height: 24px;
  margin-right: 12px;
  letter-spacing: 0.393px;
`

const ContactStore = styled.span`
  color: ${Theme.colors.blue};
  float: left;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
  letter-spacing: 0.138px;
`
const CheckCircleIcon = styled(Icon)`
  float: left;
  margin: 0 5px 0 0;
  width: 14px;
  height: 24px;
`
