import {
  OPEN_APPOINTMENT_MENU,
  GO_TO_QUICK_APPOINTMENT_FLOW,
  HEADER_CONTAINER_ID,
  STORE_LOCATOR_MODAL_PAGE,
  OPEN_NATIONWIDE_OFFERS_TAB,
  QUICK_APPOINTMENT_PAGE
} from 'constant'
import { showContentModal } from 'actions/contentModal'
import {
  expandQuickAppointmentButton,
  showStoreLocatorModal,
  activateNationwideOffersTab
} from 'helpers/custom-events'
import { push } from 'connected-react-router'
import isEmpty from 'lodash/isEmpty'
import { getIsModal } from 'selectors/modal'
import { isFunction } from 'helpers/utils'
import { GO_TO_INTERNAL_HREF, SCROLL_TO_ID, CONTENT_MODAL } from './constants'
import {
  getCurrentStore,
  getCurrentStorePhoneNumber,
  getCurrentStoreEnableAppointmentFlag
} from 'selectors/getCurrentStore'
import { get3StepperEnabled } from 'selectors/thirdPartyData'
import { showAppointmentUnsupportedModal } from 'components/Header/components/MakeAnAppointment/components/AppointmentUnsupportedModal/actions'
import { showStoreLocatorModal3StepperEnabled } from 'actions/storeLocator'

const handleStaticHref = ({ dispatch, target }) => dispatch(push(target.getAttribute('href')))

// intercepts clicks on elements with data-action=`${OPEN_APPOINTMENT_MENU}`
const handleExpandQuickAppointmentOnClick = () => expandQuickAppointmentButton()

// intercepts clicks on elements with data-action=`${GO_TO_QUICK_APPOINTMENT_FLOW}`
const handleQuickAppointmentOnClick = ({ e, dispatch, getState }) => {
  const myStoreExists = Boolean(getCurrentStore(getState()))
  const myStorePhoneNumber = getCurrentStorePhoneNumber(getState())
  const myStoreAppointmentEnableFlag = getCurrentStoreEnableAppointmentFlag(getState())
  const threeStepperEnabled = get3StepperEnabled(getState())

  if (myStoreExists && !myStoreAppointmentEnableFlag) {
    e.preventDefault()
    showAppointmentUnsupportedModal({ phoneNumber: myStorePhoneNumber })
  } else if (!myStoreExists && threeStepperEnabled) {
    e.preventDefault()
    dispatch(showStoreLocatorModal3StepperEnabled())
  } else {
    dispatch(push(QUICK_APPOINTMENT_PAGE))
  }
}

const handleScrollToID = ({ target, isModal }) => {
  const href = target.getAttribute('href')
  const targetElement = document.getElementById(href.split('#')[1])
  if (targetElement) {
    const headerHeight = document.getElementById(HEADER_CONTAINER_ID).getClientRects()[0].height
    const topOffset = targetElement.getClientRects()[0].top
    const windowScrollOffset = window.scrollY
    const newOffset = (windowScrollOffset + topOffset) - (headerHeight - 20)

    if (isModal) {
      targetElement.focus()
    } else {
      window.scrollTo(0, newOffset)
      targetElement.focus()
    }
  }
}

const handleShowStoreLocatorModal = () => showStoreLocatorModal()

const handleActivateNationwideOffersTab = () => activateNationwideOffersTab()

const handleShowContentModal = ({ target, dispatch }) => {
  const { dataset: { modal } = {}} = target

  if (modal) {
    const modalElement = document.getElementById(modal)

    if (modalElement) {
      dispatch(showContentModal({
        content: modalElement.innerHTML,
        title: modalElement.dataset.title,
        modalHeight: modalElement.dataset.height,
        modalWidth: modalElement.dataset.width,
        hideScrollY: modalElement.dataset.hidescrolly
      }))
    }
  }
}

const actionMapping = {
  [GO_TO_INTERNAL_HREF]: handleStaticHref,
  [OPEN_APPOINTMENT_MENU]: handleExpandQuickAppointmentOnClick,
  [GO_TO_QUICK_APPOINTMENT_FLOW]: handleQuickAppointmentOnClick,
  [SCROLL_TO_ID]: handleScrollToID,
  [STORE_LOCATOR_MODAL_PAGE]: handleShowStoreLocatorModal,
  [OPEN_NATIONWIDE_OFFERS_TAB]: handleActivateNationwideOffersTab,
  [CONTENT_MODAL]: handleShowContentModal
}

const getActions = target => (target.getAttribute('data-action') || '').split('|').filter(Boolean)

export const handleCustomActionsOnClick = e => (dispatch, getState) => {
  let actions = getActions(e.target)
  let target = e.target
  const isModal = getIsModal(getState())

  if (isEmpty(actions) && e.target.closest) {
    const closest = e.target.closest('[data-action]')
    if (closest && closest.contains(e.target)) {
      actions = getActions(closest)
      target = closest
    }
  }

  if (!isEmpty(actions)) {
    if ((actions || []).some(action => action in actionMapping)) {
      e.preventDefault()
      actions.forEach(action => {
        if (isFunction(actionMapping[action])) {
          actionMapping[action]({ e, dispatch, target, isModal, getState })
        }
      })
    }
  }
}
