import { connect } from 'react-redux'
import { compose } from 'redux'
import withType from 'components/common/hoc/withType'
import { showModal } from './actions'
import HeaderSearch from './HeaderSearch'
import { withState } from 'recompose'

const withIsHoveredState = withState('isHovering', 'setIsHovering', false)
const withLoadingState = withState('isLoading', 'setIsLoading', false)

export default compose(
  withType({ type: 'HeaderSearch' }),
  connect(null, { showModal }),
  withLoadingState,
  withIsHoveredState
)(HeaderSearch)
