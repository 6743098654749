import React from 'react'
import { compose, withProps, withHandlers } from 'recompose'
import { withScriptjs, withGoogleMap } from 'react-google-maps'
import { coordsToGoogleMapCoords, validateCenterCoords } from './helpers'
import { MAP_DEFAULTS } from './constants'
import Map from './Map'

const { BOT_GOOGLE_MAPS_API_KEY } = window.GLOBAL_VARS || {}

const withGoogleMapProps = withProps(({ defaultCenter = MAP_DEFAULTS.center, center }) => {
  const centerPosition = center ?
    { center: coordsToGoogleMapCoords(validateCenterCoords(center)) } :
    { defaultCenter: coordsToGoogleMapCoords(defaultCenter) }

  return {
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${BOT_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=marker,geometry,drawing,places`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />,
    ...centerPosition
  }
})

const mapEventsHandlers = () => {
  const refs = {
    map: undefined
  }

  return {
    onMapMounted: ({ onRefAvailable }) => ref => {
      refs.map = ref
      onRefAvailable && onRefAvailable(ref)
    },
    onZoomChanged: ({ onMapZoomChange }) => () => {
      if (onMapZoomChange) {
        const { lat, lng } = refs.map.getCenter()
        onMapZoomChange(refs.map.getZoom(), { lat: lat(), lng: lng() })
      }
    },
    onCenterChanged: ({ onMapCenterChange }) => () => {
      if (onMapCenterChange) {
        const { lat, lng } = refs.map.getCenter()
        const center = { lat: lat(), lng: lng() }
        onMapCenterChange(center)
      }
    },
    onBoundsChanged: ({ onMapBoundsChange }) => () => {
      if (onMapBoundsChange) {
        const bounds = refs.map.getBounds()
        const center = bounds.getCenter()
        const ne = bounds.getNorthEast()
        const RADIUS = 3963 // Earth radius in miles
        const DEGREE = 57.2958
        const lat1 = center.lat() / DEGREE
        const lon1 = center.lng() / DEGREE
        const lat2 = ne.lat() / DEGREE
        const lon2 = ne.lng() / DEGREE
        const distance = RADIUS * Math.acos(
          (Math.sin(lat1) * Math.sin(lat2)) +
          (Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1))
        )
        const { lat, lng } = refs.map.getCenter()
        const mapCenter = { lat: lat(), lng: lng() }
        onMapBoundsChange(distance, mapCenter)
      }
    },
    onIdle: ({ onMapIdle }) => () => {
      if (onMapIdle) {
        const { lat, lng } = refs.map.getCenter()
        const mapCenter = { lat: lat(), lng: lng() }
        onMapIdle(mapCenter)
      }
    }
  }
}

export default compose(
  withGoogleMapProps,
  withHandlers(mapEventsHandlers),
  withScriptjs,
  withGoogleMap
)(Map)
