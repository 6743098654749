import { connect } from 'react-redux'
import { compose, withProps, lifecycle, withHandlers } from 'recompose'
import { getCurrentStore } from 'selectors'
import { getStoreName } from 'selectors/getStoreDetails'
import { googleAnalyticPromotionImpressions, googleAnalyticPromotionClicks } from 'actions/googleAnalytic'
import { withTabBehavior } from 'components/common/Tabs'
import { OPEN_NATIONWIDE_OFFERS_TAB } from 'constant'
import { LOCAL, MENU, PROMOTIONS_CREATIVE_MAP, NATIONWIDE_OFFERS_TAB_INDEX } from './constants'
import { PromotionsList } from './PromotionsList'
import withType from 'components/common/hoc/withType'
import { sortPromotions, processPromotionLink } from './helpers'
import { navigateToPromo } from './actions'

const mapStateToProps = state => ({
  myStore: getCurrentStore(state) || {},
  storeName: getStoreName(state)
})

const withTabData = withProps(({ myStore, regionName, promotionResultList, storePromotions }) => ({
  menu: storePromotions || (myStore && myStore.address) || regionName ? MENU :
    MENU.filter(({ title }) => title !== LOCAL),
  promotionList:
    ((promotionResultList || storePromotions) && sortPromotions([...(promotionResultList || storePromotions)]))
    || [],
  eventName: OPEN_NATIONWIDE_OFFERS_TAB,
  tabToActivate: NATIONWIDE_OFFERS_TAB_INDEX
}))

const withSendAnalyticOnDidMount = lifecycle({
  componentDidMount() {
    const { storePromotions, promotionResultList, position, googleAnalyticPromotionImpressions } = this.props
    storePromotions ?
      googleAnalyticPromotionImpressions({ items: storePromotions, creative: PROMOTIONS_CREATIVE_MAP[position] }) :
      googleAnalyticPromotionImpressions({ items: promotionResultList })
  }
})

const withPromotionClickHandler = withHandlers({
  handlePromotionClick: ({ googleAnalyticPromotionClicks, position, storePromotions, regionName, navigateToPromo }) =>
    ({ item, index }) => {
      const { promotionLinkUrl } = item
      navigateToPromo(processPromotionLink({ promotionLinkUrl, regionName }))

      storePromotions ?
        googleAnalyticPromotionClicks({ item, index, creative: PROMOTIONS_CREATIVE_MAP[position] }) :
        googleAnalyticPromotionClicks({ item, index })
    }
})

export default compose(
  withType({ type: 'BOTStoreDeals' }),
  connect(mapStateToProps, { googleAnalyticPromotionImpressions, googleAnalyticPromotionClicks, navigateToPromo }),
  withTabData,
  withTabBehavior,
  withSendAnalyticOnDidMount,
  withPromotionClickHandler
)(PromotionsList)
