import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components/common/Icon/Icon'
import Button from 'components/common/Button/Button'
import Media from 'components/common/Media'
import Mixin from 'components/common/Mixin'
import Theme from 'components/common/Theme/Theme'
import Text from 'components/common/Text/Text'
import Spinner from 'components/common/Spinner'

// TODO generalize desktop and mobile styles

const HeaderSearch = ({
  mobileMainMenuExpanded,
  showModal,
  isHovering,
  setIsHovering,
  isLoading,
  setIsLoading,
  ...props
}) =>
  <div>
    {isLoading && <Spinner /> }
    <Media.Desktop>
      <SearchButton
        data-at-header-search
        onMouseEnter={ () => setIsHovering(true) }
        onMouseLeave={ () => setIsHovering(false) }
        onClick={ () => showModal(setIsLoading) }>
        <SearchIcon
          asset={ isHovering ? 'search-chartreuse' : 'search-white' }
          alt="Search icon"
          height={ 18 } />
      </SearchButton>
    </Media.Desktop>

    <Media.Mobile>
      <FormMobile
        hidden={ mobileMainMenuExpanded }
        data-at-header-search-mobile
        onClick={ () => showModal(setIsLoading) }>
        <ButtonMobile data-at-button>
          <ButtonText>
            Search
          </ButtonText>
          <Icon asset="search-blue" width={ 20 } height={ 20 } />
        </ButtonMobile>
      </FormMobile>
    </Media.Mobile>
  </div>

HeaderSearch.propTypes = {
  '@type': PropTypes.string,
  autoSuggestEnabled: PropTypes.bool,
  'endeca:auditInfo': PropTypes.shape({
    'ecr:innerPath': PropTypes.string,
    'ecr:resourcePath': PropTypes.string
  }),
  isHovering: PropTypes.bool,
  isLoading: PropTypes.bool,
  mobileMainMenuExpanded: PropTypes.bool,
  name: PropTypes.string,
  searchText: PropTypes.string,
  setIsHovering: PropTypes.func,
  setIsLoading: PropTypes.func,
  showModal: PropTypes.func,
  templateIds: PropTypes.arrayOf(PropTypes.shape())
}

export default HeaderSearch

const SearchIcon = styled(Icon)`
  margin-right: 5px;
`

const SearchButton = styled(Button)`
  width: 38px;
  height: 47px;
  padding: 0;
  margin: -5px -3px 0 3px;
  background: none;
  border: 0;
  transform: none;
  cursor: pointer;
  border-bottom: 2px solid ${Theme.colors.crimson};

  :hover {
    background: ${Theme.colors.crimson};
  }
`

const FormMobile = styled.div`
  position: relative;
  padding: 15px 0;
`

const ButtonText = styled(Text)`
  color: ${Theme.colors.blue};
  text-decoration: underline;
  ${Mixin.font.GothamBold(14)}
`

const ButtonMobile = styled.button`
  width: 86%;
  background: ${Theme.colors.white};
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  margin: 0 auto;
  padding: 10px;
  border: 0;

  img {
    width: 20px;
    height: 20px;
  }
`
