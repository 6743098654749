import { show, hide } from 'redux-modal'
import { GLOBAL_SEARCH_MODAL } from 'constant'

export const showModal = (setIsLoading) => dispatch => {
  typeof setIsLoading !== 'undefined' && setIsLoading(true)
  dispatch(show(GLOBAL_SEARCH_MODAL, { setIsLoading }))
}

export const hideGlobalSearchModal = () => dispatch =>
  dispatch(hide(GLOBAL_SEARCH_MODAL))
