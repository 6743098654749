import styled, { css } from 'styled-components'
import Theme from 'components/common/Theme/Theme'
import { Caption } from 'components/common/Text/Text'
import Mixin from 'components/common/Mixin'
import { DATEPICKER_THEME_COLOR_GREEN } from 'components/QuickAppointmentPage/ThreeStepper/constants.js'

export const DefaultWrapper = styled.div`
  position: relative;
  max-width: 480px;
  text-align: center;
`

export const DefaultMonth = styled(Caption)`
  transform: none;
  display: table-caption;
  text-align: center;
  padding-top: 5px;
  background-color: ${Theme.colors.dark_grey};
  ${Mixin.responsive('font-size', '24px', '20px')}
  ${Mixin.responsive('line-height', '34px', '30px')}
`

export const DefaultWeekDay = styled.th`
  color: ${Theme.colors.dark_grey};
  text-transform: uppercase;
  ${Mixin.responsive('padding', '10px 0', '8px 0')}
  ${Mixin.responsive('font-size', '10px', '10px')}
`

export const DefaultCell = styled.td.attrs(({
  active,
  disabled
}) => ({
  'aria-selected': active,
  'aria-disabled': disabled,
  role: 'gridcell'
}))`
  line-height: 18px;
  color: ${Theme.colors.black_new};
  cursor: pointer;
  background-color: ${Theme.colors.white};
  border: 1px solid ${Theme.colors.light_grey_border};
  ${Mixin.responsive('padding', '7px 14px', '9px')}
  ${Mixin.responsive('font-size', '12px', '10px')}
  ${props => props.empty && css`
    visibility: hidden;
  `}
  ${props => props.active && css`
    color: ${Theme.colors.white};
    background-color: ${Theme.colors.bright_red_new};
    border: 1px solid transparent;
  `}
  ${props => props.current && css`
    border: 1px solid ${Theme.colors.bright_red_new};
  `}
  ${props => props.disabled && css`
    color: ${Theme.colors.grey};
    background-color: ${Theme.colors.off_white};
    border: 1px solid transparent;
    cursor: auto;
  `}
  ${props => props.active && props.theme && props.theme === DATEPICKER_THEME_COLOR_GREEN && css`
    background-color: ${Theme.colors.green};
  `}
  ${props => props.current && props.theme && props.theme === DATEPICKER_THEME_COLOR_GREEN && css`
    border: 2px solid ${Theme.colors.green};
  `}
`
