import axios from 'axios'
import endpoints from './endpoints'
import { axiosConfig, apiConfig } from './config'
import { checkIfRedirectNeeded } from 'helpers/redirect'
import { checkStatus, checkStatusList, globalNullReplacement } from './helpers'
import setupInterceptors from './setup-interceptors'
import { getExpressAddress, verifyAddress } from './third-party/melissa-data'
import { getGooglePlaceSuggestions } from './third-party/google-place-autocomplete'
import { getBazaarVoiceItemReviews, getBazaarVoiceItemFullReviews } from './third-party/bazaarvoice-reviews'
import { getTrumpetInlineRating, getTrumpetPaginatedStoreReviews } from './third-party/trumpet-ratings'

const axiosInstance = axios.create(axiosConfig)
// Adding interceptors
setupInterceptors(axiosInstance)

/* eslint-disable camelcase */
// Workaround: check for token and make a request for save it in cookie on node...
const saveToken = ({ data } = {}) => {
  const {
    accessTokenMap: {
      access_token
    } = {}
  } = data || {}

  return !access_token ? data :
    axios
      .create({
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Requested-By': '123'
        }
      })
      .post('/saveToken', { token: access_token })
      .then(() => data)
}

// define rest service methods
const restService = {}

// common get method
restService.get = (url, options = {}) =>
  axiosInstance
    .get(url, options)
    .then(({ data, ...rest }) => Promise.resolve({ data: globalNullReplacement(data), ...rest }))
    .catch(err => {
      // TODO add winston logger here to log all errors
      // TODO add service for managing errors
      throw new Error(err)
    })

restService.getOnce = (() =>
  (url, options = {}, enableLogs) => {
    const settings = { ...options }

    if (enableLogs) {
      console.log(`GET request with following url: ${url}`)
      console.log('Request options: ', settings)
    }

    return axiosInstance
      .get(
        url,
        settings
      )
      .then(({ data, ...rest }) => Promise.resolve({ data: globalNullReplacement(data), ...rest }))
      .then(res => {
        if (enableLogs) {
          if (process.env.TA_IS_SERVER === 'true' && process.env.TA_LOG_REQUESTS === 'true') {
            console.log(`Successful scenario for following request url: ${url}`, res)
          }
          const redirectData = checkIfRedirectNeeded(res.data || {})
          redirectData && console.log('response data', JSON.stringify(res.data))
        }

        return res
      })
      .catch(err => {
        if (enableLogs) {
          console.log(`Failed scenario for following request url: ${url}`)
          if (err.response) {
            const errorText = `status: ${err.response.status}, statusText: ${err.response.statusText}`
            console.log(`Failed response ${errorText}`)
          }
        }
        if (axios.isCancel(err)) {
          console.log('Request canceled')

          return Promise.reject({ canceled: true })
        }

        throw err
      })
  }
)()

// populate API endpoints
restService.api = {
  getExpressAddress,
  verifyAddress,
  getGooglePlaceSuggestions,
  getBazaarVoiceItemReviews,
  getBazaarVoiceItemFullReviews,
  getTrumpetInlineRating,
  getTrumpetPaginatedStoreReviews
}

Object
  .keys(endpoints)
  .forEach(name => {
    const { url, method } = endpoints[name]
    const completedUrl = `${apiConfig.base}${url}`

    restService.api[name] = (...args) =>
      axiosInstance[method](completedUrl, ...args)
        .catch(err => {
          console.error(err)
          throw err
        })
        .then(saveToken)
        .then((data = {}) => {
          checkStatusList(data)
          checkStatus(data)

          return globalNullReplacement(data)
        })
  })

export default restService
