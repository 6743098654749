import { createSelector } from 'helpers/reselect'

const getEvSkuIds = state => state.addToQuote.evSkuIds
const getAddToQuoteSelector = state => state.addToQuote

export const getEvSkuIdsList = createSelector(
  [getEvSkuIds],
  evSkuIds => evSkuIds
)

export const getRecentlyAddedSku = createSelector(
  [getAddToQuoteSelector],
  ({
    recentlyAddedSku = []
  } = {}) => recentlyAddedSku
)

export const getProductsCount = createSelector(
  [getAddToQuoteSelector],
  ({
    productsCount = []
  } = {}) => productsCount
)

export const getCalendarStartDate = createSelector(
  [getAddToQuoteSelector],
  ({
    calendarStartDate = []
  } = {}) => calendarStartDate
)
