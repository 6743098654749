import { compose, withHandlers, withProps } from 'recompose'
import { connect } from 'react-redux'
import withFilteredHoverHandlers from 'components/common/hoc/withFilteredHoverHandlers'
import withHeaderHorizontalNav from 'components/common/hoc/withHeaderHorizontalNav'
import withCombinedActiveStatesAndHandlers from 'components/common/hoc/withCombinedActiveStatesAndHandlers'
import withHandlePhoneLinkClick from 'components/common/hoc/withHandlePhoneLinkClick'
import active from 'components/common/hoc/active'
import SelectedMyStore from './SelectedMyStore'
import { activateMarchexScript } from 'helpers/custom-events'
import { isHideCallStoreInfo, storeOpenTodayTomorrow, storeSpecialHoursTodayTomorrow } from './helpers'
import { getCurrentStoreIsE2EAppointment, getCurrentStorePhoneNumber } from 'selectors/getCurrentStore'
import { REQUEST_APPOINTMENT, SCHEDULE_APPOINTMENT } from 'constant'
import { getLocationPathname } from 'selectors/getPageLocation'
import withRequestApptClick from 'components/common/hoc/withRequestApptClick'

const mapStateToProps = (state) => {
  const pathname = getLocationPathname(state)

  return {
    isE2EAppointment: getCurrentStoreIsE2EAppointment(state),
    storePhoneNumber: getCurrentStorePhoneNumber(state),
    hideCallStore: isHideCallStoreInfo(pathname)
  }
}

const withMarchexCustomToggleHandler = withHandlers({
  customToggleHandler: () => () => activateMarchexScript()
})

const withHandleOpenMakeAnAppointment = withHandlers({
  handleOpenMakeAnAppointment: ({ deactivate, onClick }) => e => {
    e.preventDefault()

    deactivate()
    onClick()
  }
})

const withStoreOpenProps = withProps(({
  storeDetails: {
    store: {
      storeClosedHours = [],
      storeSpecialHours = []
    } = {}
  } = {},
  isE2EAppointment
}) => ({
  ...storeOpenTodayTomorrow(storeClosedHours),
  ...storeSpecialHoursTodayTomorrow(storeSpecialHours),
  appointmnetBtnTitle: isE2EAppointment ? SCHEDULE_APPOINTMENT : REQUEST_APPOINTMENT
}))

export default compose(
  connect(mapStateToProps),
  withMarchexCustomToggleHandler,
  active,
  withProps(() => ({ navigateToQuickApptPage: true })),
  withRequestApptClick,
  withHandleOpenMakeAnAppointment,
  withStoreOpenProps,
  withHandlePhoneLinkClick,
  withFilteredHoverHandlers,
  withCombinedActiveStatesAndHandlers,
  withHeaderHorizontalNav
)(SelectedMyStore)
