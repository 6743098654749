const getMicroservicesHost = (service) => {
  let hosts = {}
  if (window.location) {
    const hostname = window?.location?.hostname
    switch (hostname) {
      case 'localhost':
        hosts = {
          inventoryCount: 'tbc-fran-uat-get-inv-svc-facade-cr-4062568266.us-central1.run.app',
          inventory: 'tbc-fran-uat-get-inv-svc-facade-cr-zz3eluzj3q-uc.a.run.app',
          store: 'tbc-fran-uat-get-store-svc-cr-zz3eluzj3q-uc.a.run.app',
          schedule: 'tbc-fran-uat-get-schedule-appt-svc-cr-zz3eluzj3q-uc.a.run.app'
        }
        break
      case 'preview-stage.bigotires.com':
        hosts = {
          inventoryCount: 'tbc-fran-uat-get-inv-svc-facade-cr-4062568266.us-central1.run.app',
          inventory: 'tbc-fran-uat-get-inv-svc-facade-cr-zz3eluzj3q-uc.a.run.app',
          store: 'tbc-fran-uat-get-store-svc-cr-zz3eluzj3q-uc.a.run.app',
          schedule: 'tbc-fran-uat-get-schedule-appt-svc-cr-zz3eluzj3q-uc.a.run.app'
        }
        break
      case 'stage.bigotires.com':
        hosts = {
          inventoryCount: 'tbc-fran-uat-get-inv-svc-facade-cr-4062568266.us-central1.run.app',
          inventory: 'tbc-fran-uat-get-inv-svc-facade-cr-zz3eluzj3q-uc.a.run.app',
          store: 'tbc-fran-uat-get-store-svc-cr-zz3eluzj3q-uc.a.run.app',
          schedule: 'tbc-fran-uat-get-schedule-appt-svc-cr-zz3eluzj3q-uc.a.run.app'
        }
        break
      case 'preview.bigotires.com':
      case 'www.bigotires.com':
        hosts = {
          inventoryCount: 'tbc-fran-uat-get-inv-svc-facade-cr-4062568266.us-central1.run.app',
          inventory: 'tbc-fran-prod-get-inv-svc-facade-cr-p3z7p6tjaa-uc.a.run.app',
          store: 'tbc-fran-prod-get-store-svc-cr-p3z7p6tjaa-uc.a.run.app',
          schedule: 'tbc-fran-prod-get-schedule-appt-svc-cr-p3z7p6tjaa-uc.a.run.app'
        }
        break
      default:
        throw new Error('Microservices - Misconfigure environments error.')
    }
    return hosts[service]
  }
}

export default {
  inventoryCountService: {
    url: '/inventory/getInventoryCount',
    method: 'post',
    basePath: getMicroservicesHost('inventoryCount')
  },
  inventoryService: {
    url: '/inventory/getInventory',
    method: 'post',
    basePath: getMicroservicesHost('inventory')
  },
  storeService: {
    url: '/store/fetchdetails',
    method: 'post',
    basePath: getMicroservicesHost('store')
  },
  appointmentSchedulingService: {
    url: '/scheduleAppointment',
    method: 'post',
    basePath: getMicroservicesHost('schedule')
  }
}
