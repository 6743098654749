import { createAction } from 'redux-actions'
import { rest } from 'services'
import { getMiniCartData, cleanShoppingData } from 'actions/shoppingData'
import { init } from 'components/Main/actions'
import { setVehicleDetails } from 'actions/confirmTireSize'
import { QUICK_APPOINTMENT_PAGE } from 'constant'

export const setDateData = createAction('APPOINTMENT/SET_DATE_DATA')

export const setServicesList = createAction('APPOINTMENT/SET_SERVICES_LIST')

export const setActivePromotions = createAction('APPOINTMENT/SET_ACTIVE_PROMOTIONS_LIST')

export const setCurrentStep = createAction('APPOINTMENT/SET_CURRENT_STEP')

export const setPreviousStep = createAction('APPOINTMENT/SET_PREVIOUS_STEP')

export const setDeletedPromotions = createAction('APPOINTMENT/SET_DELETED_PROMOTIONS_LIST')

export const setVehiclesList = createAction('APPOINTMENT/SET_VEHICLES_LIST')

export const setAppointmentActiveVehicle = createAction('APPOINTMENT/SET_APPOINTMENT_ACTIVE_VEHICLE')

export const setIsTrailer = createAction('APPOINTMENT/SET_IS_TRAILER')

export const setQuoteProductAndServices = createAction('APPOINTMENT/SET_QUOTE_PRODUCTS_AND_SERVICES')

export const clearQuickAppointmentData = createAction('APPOINTMENT/CLEAR_QUICK_APPOINTMENT_DATA')

export const setAppointmentResponse = createAction('APPOINTMENT/SET_RESPONSE')

export const setQuickApptInitialized = createAction('APPOINTMENT/QUICK_APPT_INITIALIZED')

export const setPreSelectedServices = createAction('APPOINTMENT/SET_PRE_SELECTED_SERVICES')

export const makeActiveVehicle = ({
  userVehicleId,
  masterVehicle,
  setVehicleSelectorIsLoading
} = {}) => (dispatch) =>
  rest.api.makeActiveVehicle({
    activeVehicleId: userVehicleId
  })
    .then(() => {
      dispatch(cleanShoppingData())
      dispatch(setVehicleDetails(masterVehicle))
      dispatch(getMiniCartData())
      return dispatch(init({ pathname: QUICK_APPOINTMENT_PAGE, setLocalLoadingState: setVehicleSelectorIsLoading }))
    })
